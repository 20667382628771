import React, { Component } from 'react';
import axios from 'axios';
import { IonList, IonItem, IonLabel, IonContent, IonListHeader } from '@ionic/react';

export default class Users extends React.Component<{},any> {
    constructor(props) {
        super(props);
        this.state = { usersCollection: [] };
    }
    componentDidMount() {
        axios.get('../assets/verjaardagen.json')
            .then(res => {
                this.setState({ usersCollection: res.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    dataTable(month) {
        return Object.keys(this.state.usersCollection).map((a, b) => (
            Object.keys(this.state.usersCollection[a]).map((c, d) => {
                if (this.state.usersCollection[a][c].month === month) {
                    return (<IonItem key={d}>
                        <IonLabel>{c} <span className={`day ${ this.state.usersCollection[a][c].event }`}>{this.state.usersCollection[a][c].day}</span>
                        </IonLabel>
                    </IonItem>)
                }
            })
        ));
    }
    render() {
        return (
            <IonContent>
                <IonList>
                    <IonListHeader>
                        <IonLabel color="primary">Januari</IonLabel>
                    </IonListHeader>
                    {this.dataTable('01')}

                    <IonListHeader>
                        <IonLabel color="primary">Februari</IonLabel>
                    </IonListHeader>
                    {this.dataTable('02')}

                    <IonListHeader>
                        <IonLabel color="primary">March</IonLabel>
                    </IonListHeader>
                    {this.dataTable('03')}

                    <IonListHeader>
                        <IonLabel color="primary">April</IonLabel>
                    </IonListHeader>
                    {this.dataTable('04')}

                    <IonListHeader>
                        <IonLabel color="primary">May</IonLabel>
                    </IonListHeader>
                    {this.dataTable('05')}

                    <IonListHeader>
                        <IonLabel color="primary">June</IonLabel>
                    </IonListHeader>
                    {this.dataTable('06')}

                    <IonListHeader>
                        <IonLabel color="primary">July</IonLabel>
                    </IonListHeader>
                    {this.dataTable('07')}

                    <IonListHeader>
                        <IonLabel color="primary">Augustus</IonLabel>
                    </IonListHeader>
                    {this.dataTable('08')}

                    <IonListHeader>
                        <IonLabel color="primary">September</IonLabel>
                    </IonListHeader>
                    {this.dataTable('09')}
    
                    <IonListHeader>
                        <IonLabel color="primary">October</IonLabel>
                    </IonListHeader>
                    {this.dataTable('10')}

                    <IonListHeader>
                        <IonLabel color="primary">November</IonLabel>
                    </IonListHeader>
                    {this.dataTable('11')}

                    <IonListHeader>
                        <IonLabel color="primary">December</IonLabel>
                    </IonListHeader>
                    {this.dataTable('12')}
                </IonList>
            </IonContent>
        )
    }
}