import React, { Component } from 'react';
import axios from 'axios';
import { IonList, IonItem, IonLabel, IonContent, IonListHeader } from '@ionic/react';

export default class Users extends React.Component<{},any> {
    constructor(props) {
        super(props);
        this.state = { usersCollection: [] };
    }
    componentDidMount() {
        axios.get('../assets/verjaardagen.json')
            .then(res => {
                this.setState({ usersCollection: res.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    dataTable(month) {
        const today = new Date();
        const f = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7).getDate()

        return Object.keys(this.state.usersCollection).map((a, b) => (
            Object.keys(this.state.usersCollection[a]).map((c, d) => {
                if (this.state.usersCollection[a][c].month === month 
                && this.state.usersCollection[a][c].day < f) {
                    return (<IonItem key={d}>
                        <IonLabel>{c} <span className={`day ${ this.state.usersCollection[a][c].event }`}>{this.state.usersCollection[a][c].day}</span>
                        </IonLabel>
                    </IonItem>)
                }
            })
        ));
    }
    getMonth() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      
      const d = new Date();
      const e = monthNames[d.getMonth()];
      return <div>{e}</div>
    }
    render() {
        return (
            <IonContent>
                <IonList>
                    <IonListHeader>
                        <IonLabel color="primary">{this.getMonth()}</IonLabel>
                    </IonListHeader>
                    {this.dataTable(("0" + (new Date().getMonth() + 1)).slice(-2))}
                </IonList>
            </IonContent>
        )
    }
}